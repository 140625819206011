.change-history-graph {
  .line {
    transition: ease-out 0.2s;
  }
  .collapse-icon {
    fill: #f6f6f6;
    stroke: #5d5555;

    &:hover {
      fill: #f8a6a6;
      cursor: pointer;
    }
  }
  .collapse-icon-text {
    fill: #5d5555;
    font-size: 9pt;
    pointer-events: none;
    transition: ease-out 0.2s;
  }
  .marker0 {
    fill: #fff0d0;
    stroke-width: 1px;
    stroke: #debe6c;
    transition: ease-out 0.2s;

    &:hover {
      fill: #debe6c;
      cursor: pointer;
    }
  }

  .marker1 {
    fill: #bef8a5;
    stroke-width: 1px;
    stroke: #2cb604;
    transition: ease-out 0.2s;

    &:hover {
      fill: #2cb604;
      cursor: pointer;
    }
  }

  .marker2 {
    fill: #b0daf8;
    stroke-width: 1px;
    stroke: #36c0fd;
    transition: ease-out 0.2s;

    &:hover {
      fill: #36c0fd;
    }
  }

  .marker3 {
    fill: #fcc191;
    stroke-width: 1px;
    stroke: #fa7c0e;
    transition: ease-out 0.2s;

    &:hover {
      fill: #fa7c0e;
      cursor: pointer;
    }
  }

  .marker4 {
    fill: #cfbcfa;
    stroke-width: 1px;
    stroke: #8c5ffa;
    transition: ease-out 0.2s;

    &:hover {
      fill: #8c5ffa;
      cursor: pointer;
    }
  }

  .marker5 {
    fill: #f5ec89;
    stroke-width: 1px;
    stroke: #cbbf3d;
    transition: ease-out 0.2s;

    &:hover {
      fill: #cbbf3d;
      cursor: pointer;
    }
  }

  .marker6 {
    fill: #fca2ac;
    stroke-width: 1px;
    stroke: #fd5f72;
    transition: ease-out 0.2s;

    &:hover {
      fill: #fd5f72;
      cursor: pointer;
    }
  }

  .marker-end {
    fill: #efefef;
    stroke-width: 1px;
    stroke: #b7b7b7;
    transition: ease-out 0.2s;

    &:hover {
      fill: #b7b7b7;
      cursor: pointer;
    }
  }
}
