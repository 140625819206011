.loader {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ccc;
    opacity: 0.3;
    height: 100%;
    z-index: 9999;
    pointer-events: none;
    cursor: default;
}

.editorIcon:hover {
    filter: brightness(130%);
}

.accordionHeader {
    display: flex;
    align-items: center;
    gap: 10px;
}

.accordionHeaderText {
    font-size: 16px;
    font-weight: 600;
}

.accordionHeaderTextMuted {
    font-size: 14px;
    color: #b7b7b7;
}
