body,
input,
textarea,
button {
  margin: 0;
  font-family: 'Inter-Regular', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter_Regular'),
    url(./assets/fonts/Inter-Regular.woff2) format('woff2');
}
@font-face {
  font-family: 'Inter-SemiBold';
  src: local('Inter_SemiBold'),
    url(./assets/fonts/Inter-SemiBold.woff2) format('woff2');
}
@font-face {
  font-family: 'Inter-Bold';
  src: local('Inter_Bold'), url(./assets/fonts/Inter-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Inter-Medium';
  src: local('Inter_Medium'),
    url(./assets/fonts/Inter-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Inter-ExtraBold';
  src: local('Inter_Medium'),
    url(./assets/fonts/Inter-ExtraBold.woff2) format('woff2');
}

@font-face {
  font-family: 'Inter-Light';
  src: local('Inter_Medium'),
    url(./assets/fonts/Inter-Light.woff2) format('woff2');
}
