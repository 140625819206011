.timelinegraph {

}

.timelineIcon {
  fill: #fff;
  stroke: #77bcf3;
  stroke-width: 1px;
  stroke-linejoin: round;

  &:hover {
    cursor: pointer;
    fill: #e5f1fc;
  }
}

.selectedIcon {
  fill: #77bcf3;
  pointer-events: none;
}


.timeLineIconText {
  fill: #77bcf3;
  dominant-baseline: central;
  pointer-events: none;
}

.selectedText {
  fill: #fff;
}

