.container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 112px);
    gap: 10px;
}

.header {
    display: flex;
    gap: 10px;
}

.headerSectionLarge {
    display: flex;
    flex: 1.078;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
}

.headerSectionSmall {
    flex: 0.5;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.body {
    display: flex;
    height: 100%;
    gap: 10px;
}

.canvas {
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: calc(100% - 50px);
    overflow-y: auto;
}

.canvas::-webkit-scrollbar {
    display: none;
}

.canvasButtons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.canvasButton {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.canvasContainer {
    flex: 1;
    display: flex;
    gap: 10px;
    height: calc(100vh - 160px);
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    cursor: grab;
}

.treeViewContainer {
    flex: 1;
    display: flex;
    gap: 10px;
    height: calc(100vh - 160px);
    overflow-y: auto;
}

.schemaSide {
    height: 100%;
    overflow-y: auto;
    background: #fff;
    padding: 10px 0;
    width: 340px;
    border-radius: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.schemaSide::-webkit-scrollbar {
    display: none;
}

.loaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 10px;
}

.loaderText {
    font-size: 14px;
    color: #ccc;
}

.noTablesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;;
    height: 300px;
    gap: 20px;
    justify-content: center;
}

.noTablesBold {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
}

.noTablesLight {
    font-size: 16px;
    font-weight: 400;
}

.dateTime {
    font-size: 14px;
}

.textField {
    margin-left: auto;
}


@media screen and (min-width: 1440px) {
    .container {
        margin: 0 auto;
        max-width: 1400px;
    }
}
