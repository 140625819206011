.run-records-graph {
  .bar0 {
    fill: #7a99cb;
    transition: ease-out 0.2s;


    &:hover {
      fill: #65779f;
      cursor: pointer;
    }

    &.selected {
      fill: #debe6c;
    }
  }

  .bar1 {
    fill: #65779f;
    transition: ease-out 0.2s;

    &:hover {
      fill: #2c9b06;
      cursor: pointer;
    }

    &.selected {
      fill: #2c9b06;
    }
  }

  .bar2 {
    fill: #85cdfc;
    transition: ease-out 0.2s;

    &:hover {
      fill: #06739b;
    }

    &.selected {
      fill: #06739b;
    }
  }

  .bar3 {
    fill: #fdbc71;
    transition: ease-out 0.2s;

    &:hover {
      fill: #b76a26;
      cursor: pointer;
    }

    &.selected {
      fill: #b76a26;
    }
  }

  .bar4 {
    fill: #a071fd;
    transition: ease-out 0.2s;

    &:hover {
      fill: #502da1;
      cursor: pointer;
    }

    &.selected {
      fill: #502da1;
    }
  }

  .bar5 {
    fill: #fcf464;
    transition: ease-out 0.2s;

    &:hover {
      fill: #a89f03;
      cursor: pointer;
    }

    &.selected {
      fill: #a89f03;
    }
  }

  .bar6 {
    fill: #f5808e;
    transition: ease-out 0.2s;

    &:hover {
      fill: #831f2b;
      cursor: pointer;
    }

    &.selected {
      fill: #831f2b;
    }
  }
}
